<template>
  <ValidationObserver @submit="saveNotification()">
    <UiModalContainer
      :prevent-out-side-close="true"
      :modal-show="showModal"
      :modal-width="50"
      :modal-padding-top="true"
      footer-classes="flex justify-end gap-2 sm:gap-3"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit">
          <span v-i18n="dashboard">Edit</span>
          {{ edit.title }}
        </span>
        <span v-else v-i18n="dashboard">SS_NOTIFIER</span>
      </template>
      <template v-slot>
        <div v-show="!isCustomNotification">
          <span class="py-2 text-sm">Select User</span>
          <span class="text-text-color-danger pl-1">*</span>
          <div class="outline grid grid-cols-4 content-center">
            <button
              v-for="(detail, index) in userButtons"
              :key="index"
              type="button"
              :class="detail.class"
              class="capitalize w-auto m-0.5 text-center sm:text-base text-sm text-menu py-2 SSP"
              @click="setSelectedUser(detail.user)"
            >
              {{ $t(`dashboard.${detail.user}`) }}
            </button>
          </div>
          <div v-if="!selectedUser && isFormSubmit" class="text-text-color-danger pt-1 text-sm">
            kindly select atleast one user
          </div>
          <div class="pt-5">
            <InputFieldWrapper v-if="showClassSection">
              <UiSingleSelect
                v-model="selectedClass"
                title="CLASS"
                :options="usersClassList"
                label="title"
                reduce="id"
                :disabled="!!isStatsDisplay"
                :class="$style.widthItems"
                class="flex-1"
                :search-through-api="true"
                input-color="bg-white"
                @search="_getClassData"
              />
              <UiSingleSelect
                v-model="selectedSection"
                reduce="id"
                title="Section"
                :options="usersSectionList"
                :disabled="isStatsDisplay || !selectedClass"
                :class="$style.widthItems"
                class="flex-1"
                :search-through-api="true"
                input-color="bg-white"
                @search="getSection"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiMultiSelect
                v-model="formbody.users"
                :title="userTitle"
                :label="'full_name'"
                :image="true"
                :options="users"
                :already-selected="formbody.users"
                :class="$style.widthItems"
                :disabled="!!isStatsDisplay"
                :parent-class="userClasses"
                class="flex-1"
                input-color="bg-white"
                :search-through-api="true"
                @search="_getUsersData"
              />
              <UiMultiSelect
                v-if="selectedUser === TENANT_ROLES.GUARDIAN"
                v-model="formbody.guardians"
                title="GUARDIAN"
                :options="guardiansList"
                :already-selected="formbody.guardians"
                :class="$style.widthItems"
                class="flex-1"
                :image="true"
                :disabled="!!isStatsDisplay"
                label="full_name"
                input-color="bg-white"
                parent-class="xs:w-34 w-full"
                full-name-class="w-12 sm:w-full"
                :search-through-api="true"
                @search="_getGaurdianData"
              />
            </InputFieldWrapper>
          </div>

          <hr class="border-primary-grey mt-6" />
          <div class="pt-6 text-base flex-col flex flex-wrap justify-between">
            <UiCheckBox
              :model-value="selectedMedium.emailCheck"
              label="Email"
              type="checkbox"
              name="medium"
              :rules="emailRules"
              value="emailCheck"
              :halt-update="!!isStatsDisplay"
              :disabled="!emailEnabled"
              @change="setSelectedMedium"
            />
            <UiCheckBox
              :model-value="selectedMedium.pushnotificationCheck"
              label="In-App Notification"
              type="checkbox"
              name="medium"
              :halt-update="!!isStatsDisplay"
              :rules="webPushRules"
              value="pushnotificationCheck"
              @change="setSelectedMedium"
            />
            <UiCheckBox
              :model-value="selectedMedium.smsCheck"
              label="SMS"
              :disabled="!smsEnabled"
              type="checkbox"
              name="medium"
              :rules="smsRules"
              value="smsCheck"
              :halt-update="!!isStatsDisplay"
              @change="setSelectedMedium"
            />
          </div>
          <div
            v-if="!selectedMedium.defaultShow && isFormSubmit"
            class="text-text-color-danger pt-1 text-sm"
          >
            kindly select atleast one medium
          </div>
          <InputFieldWrapper v-if="selectedMedium.defaultShow">
            <UiSingleSelect
              v-model="occasionSelect"
              title="OCCASION"
              :disabled="!!isStatsDisplay"
              :options="occasionList"
              :class="$style.widthItems"
              reduce="id"
              class="flex-1"
              :search-through-api="true"
              @search="_getOccassions"
            />
            <UiInputBox
              v-model="notificationTitle"
              title="Title"
              type="text"
              placeholder="write"
              :disabled="!!isStatsDisplay"
              class="flex-1"
              rules="required|max:255"
              input-color="bg-white"
              :class="$style.widthItems"
            />
          </InputFieldWrapper>
          <div v-if="selectedMedium.defaultShow">
            <div v-if="selectedMedium.emailCheck">
              <span class="text-sm font-bold">Email Body</span>
              <UiInputBox
                v-model="emailBody"
                type="textarea"
                name="Email Description"
                title="Email Description"
                class="mt-2"
                :disabled="!!isStatsDisplay"
                rules="required|between:15,1000"
                placeholder="Write"
              />
            </div>
            <div v-if="selectedMedium.pushnotificationCheck">
              <span class="text-sm font-bold">In-App Notification Body</span>
              <UiInputBox
                v-model="pushBody"
                type="textarea"
                :disabled="!!isStatsDisplay"
                name="In App Description"
                title="In App Description"
                rules="required|between:15,1000"
                placeholder="Write"
              />
            </div>
            <div v-if="selectedMedium.smsCheck">
              <span class="text-sm font-bold">SMS Body</span>
              <UiInputBox
                v-model="smsBody"
                type="textarea"
                name="Sms Description"
                :disabled="!!isStatsDisplay"
                title="Sms Description"
                rules="required|between:15,1000"
                class="mt-2"
                placeholder="Write"
              />
            </div>
          </div>
        </div>
        <!-- Customize notification part -->
        <CustomizeAnnouncememtDetails
          v-if="isCustomNotification"
          :fetch-custom-payload="fetchCustomPayload"
          :users="receiverDetails.receivers || []"
          :email-body="receiverDetails.email_body"
          :push-body="receiverDetails.webpush_body"
          :sms-body="receiverDetails.message_body"
          :announcement-type="receiverDetails.announcement_type_id"
          :notification-title="receiverDetails.title"
          :section="receiverDetails.section_id"
          :current-class="receiverDetails.class_id"
          :role="receiverDetails.receiver_role_id"
          :department_id="receiverDetails.department_id"
          :selected-medium="selectedMedium"
          @goBack="handleClick('goBack')"
          @saveCustomNotification="saveCustomNotification"
        />
        <div
          v-if="isStatsDisplay"
          class="mx-6 p-2 mb-5 border rounded-md border-primary-grey"
          :class="isHighlightSection && 'highlight-section'"
        >
          <span class="text-label-text text-2xl font-medium">This announcement will made to:</span>
          <DetailCard :details="announcementsPreStats" class="mt-5" />
        </div>
      </template>
      <template v-slot:footer>
        <UIButton
          v-if="!isCustomNotification && !isStatsDisplay"
          button="primary"
          class="mr-auto"
          @click="customizeNotification"
        >
          Customize List
        </UIButton>

        <UIButton
          v-if="isCustomNotification"
          button="primary"
          class="mr-auto"
          @click="handleClick('goBack')"
        >
          Back
        </UIButton>

        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('cancel')">Cancel</UIButton>

          <UIButton v-if="isStatsDisplay" button="primary" @click="makeNewAnnouncement()">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>Confirm</span>
          </UIButton>

          <UIButton
            v-else
            button="primary"
            @click="isCustomNotification ? submitCustomNotification : ''"
          >
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>Send</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import UiInputBox from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import CustomizeAnnouncememtDetails from '@views/notifications/SendNotificationView.vue'
import { fullName } from '@/src/utils/settings/tenant-user.util'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import { removeEmptyKeysFromObject } from '@utils/generalUtil.js'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import { transformTextToCapitalize } from '@utils/generalUtil'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import UiCheckBox from '@components/UiElements/UiCheckbox.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    Loader,
    UiInputBox,
    UiSingleSelect,
    DetailCard,
    UiModalContainer,
    ValidationObserver,
    UiMultiSelect,
    CustomizeAnnouncememtDetails,
    UiCheckBox,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    edit: { type: Object, default: () => {} },
  },
  emits: ['updateResult'],
  data() {
    return {
      TENANT_ROLES,
      GENERAL_CONSTANTS,
      formbody: {},
      forceRender: 0,
      forceRenderSection: 0,
      forceRenderGuardian: 0,
      emailBody: '',
      pushBody: '',
      smsBody: '',
      notificationTitle: '',
      occasionSelect: '',
      title: 'title',
      selectedUser: null,
      selectedMedium: {
        emailCheck: false,
        smsCheck: false,
        pushnotificationCheck: false,
        defaultShow: false,
      },
      isFormSubmit: false,
      users: [],
      departmentsArray: [],
      dashboard: 'dashboard',
      occasionList: [],
      selectedClass: null,
      selectedSection: null,
      isCustomList: false,
      guardiansList: [],
      smsEnabled: true,
      emailEnabled: true,
      receiverDetails: null,
      isCustomNotification: false,
      fetchCustomPayload: false,
      isStatsDisplay: false,
      announcementsPreStats: [],
      isHighlightSection: false,
      excludeCountKeysWhenMediumNotAllowed: ['sms_count', 'webpush_count', 'email_count'],
    }
  },

  computed: {
    ...mapState('classes', ['usersClassList']),
    ...mapState('section', ['usersSectionList']),
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
      showModal: (state) => state.layout.showModal,
      campusId: (state) => state.layout.currentCampusScope.id,
    }),
    userTitle() {
      return this.selectedUser === TENANT_ROLES.GUARDIAN ? 'STD_NAME' : 'NAME'
    },
    userButtons() {
      return [
        {
          class: this.staffClasses,
          user: TENANT_ROLES.STAFF,
        },
        { class: this.studentClasses, user: TENANT_ROLES.SECTION_STUDENT },
        { class: this.guardianClasses, user: TENANT_ROLES.GUARDIAN },
        { class: this.teacherClasses, user: TENANT_ROLES.SECTION_TEACHER },
      ]
    },
    userClasses() {
      return this.selectedUser === TENANT_ROLES.GUARDIAN
        ? 'xs:w-34 w-full'
        : 'xs:w-72 w-82 sm:w-full'
    },
    showClassSection() {
      return (
        this.selectedUser === TENANT_ROLES.SECTION_TEACHER ||
        this.selectedUser === TENANT_ROLES.SECTION_STUDENT ||
        this.selectedUser === TENANT_ROLES.GUARDIAN
      )
    },
    guardianClasses() {
      return this.selectedUser === TENANT_ROLES.GUARDIAN
        ? 'textColor bg-primary-green '
        : 'bg-white'
    },
    studentClasses() {
      return this.selectedUser === TENANT_ROLES.SECTION_STUDENT
        ? 'textColor bg-primary-green '
        : 'bg-white'
    },
    teacherClasses() {
      return this.selectedUser === TENANT_ROLES.SECTION_TEACHER
        ? 'textColor bg-primary-green '
        : 'bg-white'
    },
    staffClasses() {
      return this.selectedUser === TENANT_ROLES.STAFF ? 'textColor bg-primary-green ' : 'bg-white'
    },
    emailRules() {
      return this.selectedMedium.pushnotificationCheck ||
        this.selectedMedium.smsCheck ||
        !this.emailEnabled
        ? ''
        : 'required'
    },

    smsRules() {
      return this.selectedMedium.pushnotificationCheck ||
        this.selectedMedium.emailCheck ||
        !this.smsEnabled
        ? ''
        : 'required'
    },
    webPushRules() {
      return this.selectedMedium.emailCheck || this.selectedMedium.smsCheck ? '' : 'required'
    },
  },
  watch: {
    isHighlightSection: {
      handler(value) {
        if (value)
          setTimeout(() => {
            this.isHighlightSection = false
          }, 7000)
      },
    },
    'formbody.users': {
      handler() {
        this._getGaurdianData()
      },
    },
    selectedUser: {
      deep: true,
      handler(val) {
        this.selectedClass = ''
        this.selectedSection = ''
        this.formbody.users = []
        this.forceRender++
      },
    },
    selectedSection: {
      handler() {
        this.forceRender++
        this.formbody.users = []
        this.forceRenderGuardian++
        this.formbody.guardians = []

        this._getUsersData()
      },
      deep: true,
    },
    selectedClass: {
      handler(val) {
        this.formbody.users = []
        this.getSectionsFromClass(val)
        this.selectedSection = ''
        this._getUsersData()
      },
    },
  },
  mounted() {
    this._getOccassions()
    this._getSmsEmailConfig()
  },
  methods: {
    transformTextToCapitalize,
    submitCustomNotification() {
      this.fetchCustomPayload = !this.fetchCustomPayload
    },
    saveNotification() {
      let announcementsStats = []
      this.isFormSubmit = true
      this.receiverDetails = this.prepareSendObject()
      if (!this.receiverDetails) return
      if (!this.isCustomNotification) {
        this.getPreSendAnnouncementStats(this.receiverDetails).then((res) => {
          announcementsStats = res?.data
          this.announcementsPreStats = Object.fromEntries(
            Object.entries(announcementsStats).filter(
              ([key]) =>
                this.hideStatsWhenMediumNotAvailable(key) &&
                this.hideClassSectionStatsForStaff(key, this.receiverDetails.receiver_role_id),
            ),
          )
          this.isStatsDisplay = true
          this.isHighlightSection = true
        })
      }
    },
    hideStatsWhenMediumNotAvailable(key) {
      let mediums = this.receiverDetails?.mediums || []
      return !!!(
        this.excludeCountKeysWhenMediumNotAllowed.includes(key) &&
        !mediums.includes(key.split('_')[0])
      )
    },
    hideClassSectionStatsForStaff(key, receiver_role_id) {
      return !!!(
        receiver_role_id === TENANT_ROLES.STAFF && ['class_count', 'section_count'].includes(key)
      )
    },
    makeNewAnnouncement() {
      let payload = this.receiverDetails
      this.createAnnouncemnt(payload).then(() => {
        this.handleClick('close')
        this.$emit('updateResult')
        this.isStatsDisplay = false
      })
    },

    generateToast(message) {
      this.$store.commit('toast/NEW', { type: 'error', message: message }, { root: true })
    },

    prepareSendObject() {
      /* Check user array */
      const payload = {
        receiver_role_id: '',
        receivers: [],
        department_id: null,
        mediums: [],
        title: this.notificationTitle,
        message_body: this.smsBody,
        email_body: this.emailBody,
        webpush_body: this.pushBody,
        announcement_type_id: this.occasionSelect,
      }
      /* STAFF */
      if (this.selectedUser === TENANT_ROLES.STAFF) {
        payload.receiver_role_id = TENANT_ROLES.STAFF
        payload.receivers = this.formbody.users
      }
      /* STUDENT */
      if (this.selectedUser === TENANT_ROLES.SECTION_STUDENT) {
        payload.receiver_role_id = TENANT_ROLES.SECTION_STUDENT
        payload.receivers = [...this.formbody.users]
        payload.section_id = this.selectedSection
        payload.class_id = this.selectedClass
      }
      /* GUARDIAN */
      if (this.selectedUser === TENANT_ROLES.GUARDIAN) {
        payload.receiver_role_id = TENANT_ROLES.GUARDIAN
        payload.student_id = null
        payload.receivers = [...this.formbody.guardians]
        payload.section_id = this.selectedSection
        payload.class_id = this.selectedClass
      }
      /* TEACHER */
      if (this.selectedUser === TENANT_ROLES.SECTION_TEACHER) {
        payload.receiver_role_id = TENANT_ROLES.SECTION_TEACHER
        payload.receivers = [...this.formbody.users]
        payload.section_id = this.selectedSection
        payload.class_id = this.selectedClass
      }

      if (
        !this.selectedMedium.defaultShow ||
        (!this.selectedMedium.emailCheck &&
          !this.selectedMedium.smsCheck &&
          !this.selectedMedium.pushnotificationCheck)
      ) {
        return false
      }
      if (!this.notificationTitle) {
        return false
      }
      if (!this.selectedUser) {
        return false
      }
      if (this.selectedMedium.emailCheck) {
        payload.mediums.push('email')
        if (!this.emailBody) {
          return false
        }
      }
      if (this.selectedMedium.smsCheck) {
        payload.mediums.push('sms')
        if (!this.smsBody) {
          return false
        }
      }
      if (this.selectedMedium.pushnotificationCheck) {
        payload.mediums.push('webpush')
        if (!this.pushBody) {
          return false
        }
      }

      return payload
    },

    customizeNotification() {
      this.receiverDetails = this.prepareSendObject()
      if (this.receiverDetails) this.isCustomNotification = true
    },
    saveCustomNotification(payload) {
      if (!payload) return
      if (payload && !payload.receivers.length) {
        return
      }
      this.getPreSendAnnouncementStats(payload).then((res) => {
        let announcementsStats = res?.data
        this.isStatsDisplay = true
        this.isCustomNotification = false
        this.receiverDetails = payload
        this.isHighlightSection = true
      })
    },
    getSection(query = '') {
      if (query) this.getSectionsFromClass(this.selectedClass, query)
    },
    getSectionsFromClass(id, query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', id || ''),
        },
      }
      this.filterUsersSectionsList(payload)
    },

    setSelectedUser(user) {
      if (this.isStatsDisplay) return
      this.selectedUser = user
      switch (user) {
        case TENANT_ROLES.STAFF: {
          this._getStaffData()
          break
        }
        case TENANT_ROLES.SECTION_STUDENT: {
          this._getClassData()
          this._getUsersData()
          break
        }
        case TENANT_ROLES.GUARDIAN: {
          this._getUsersData()
          this._getClassData()
          this._getGaurdianData()

          break
        }
        case TENANT_ROLES.SECTION_TEACHER: {
          this._getClassData()
          this._getUsersData()
          break
        }
      }
    },

    async _getStaffData(payload) {
      const res = await this.getStaffWithFilters(payload)
      this.users = res?.data?.records
    },

    async _getUsersData(query = '') {
      const payload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) } }
      let res

      if (
        this.selectedUser === TENANT_ROLES.SECTION_STUDENT ||
        this.selectedUser === TENANT_ROLES.GUARDIAN
      ) {
        if (this.selectedUser === TENANT_ROLES.SECTION_STUDENT) {
          payload.$where = {
            ...payload.$where,
            ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', this.selectedClass || ''),
            ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.selectedSection || ''),
          }
        } else if (this.selectedUser === TENANT_ROLES.GUARDIAN) {
          payload.$where = {
            ...payload.$where,
            ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', this.selectedClass || ''),
            ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.selectedSection || ''),
          }
        }
        removeEmptyKeysFromObject(payload.$where)
        res = await this.getStudentsWithFilters(payload)
        this.users = res?.records
      }
      if (this.selectedUser === TENANT_ROLES.STAFF) {
        this._getStaffData(payload)
      }
      if (this.selectedUser === TENANT_ROLES.SECTION_TEACHER) {
        payload.$where = {
          ...payload.$where,
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', this.selectedClass || ''),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.selectedSection || ''),
        }
        removeEmptyKeysFromObject(payload.$where)
        res = await this.getTeacherWithFilters(payload)
        this.users = res?.data?.records || []
        this.users.map((teacher) => {
          fullName(teacher)
        })
      }
    },
    async _getGaurdianData(query = '') {
      const payload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) } }

      if (this.selectedUser === TENANT_ROLES.GUARDIAN) {
        payload.$where = {
          ...payload.$where,
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', this.selectedClass || ''),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.selectedSection || ''),
        }
      }
      if (this.formbody.users && this.formbody.users.length) {
        let studentIds = []
        this.formbody.users.forEach((std) => {
          studentIds.push(std.student_id)
        })
        payload.$where = {
          ...payload.$where,
          ...buildWhereQuery(FILTER_KEYS.IN, 'student_ids', studentIds),
        }
      }
      removeEmptyKeysFromObject(payload.$where)
      const res = await this.getGuardiansWithFilter(payload)
      this.guardiansList = res?.records
      this.guardiansList?.map((guardian) => {
        fullName(guardian)
      })
    },

    _getClassData(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getUserClasses(payload)
    },

    _getOccassions(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getAnnouncementTypes(payload).then((response) => {
        let [res, err] = response
        this.occasionList = res?.data?.records
      })
    },

    async _getSmsEmailConfig() {
      const [res, err] = await this.getSmsEmailConfig()
      this.emailEnabled = res?.data?.email_status
      this.smsEnabled = res?.data?.sms_status
    },

    setSelectedMedium({ value, status }) {
      if (this.isStatsDisplay) return
      this.selectedMedium[value] = status
      this.selectedMedium.defaultShow = status
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowModal(true)
          break
        case 'close':
          this.setShowModal(false)
          break
        case 'cancel':
          this.setShowModal(false)
          break
        case 'confirm':
          this.setShowModal(false)
          break
        case 'goBack':
          this.isCustomNotification = false
          break
      }
    },

    ...mapActions('announcemettypes', ['getAnnouncementTypes']),
    ...mapActions('notifications', [
      'createAnnouncemnt',
      'getSmsEmailConfig',
      'getPreSendAnnouncementStats',
    ]),
    ...mapActions('layout', ['setRightbarContent', 'setRightBarStats', 'setShowModal']),
    ...mapActions('section', ['filterUsersSectionsList']),
    ...mapActions('classes', ['getUserClasses']),
    ...mapActions('student', ['getStudentsWithFilters']),
    ...mapActions('staff', ['getStaffWithFilters']),
    ...mapActions('guardian', ['getGuardiansWithFilter']),
    ...mapActions('teacher', ['getTeacherWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>

<style lang="scss" scoped>
.outline {
  width: 400px;
  height: 48px;
  background: #fff;
  border: 1px solid #d4d5d9;
  border-radius: 7px;
  opacity: 1;
}
.SSP {
  border: 1px solid #f4f5fa;
  border-radius: 7px;
}
.textColor {
  color: white;
}

@media (max-width: 640px) {
  .primary-button-custom-width {
    min-width: 74px;
  }
  .outline {
    width: auto;
    height: auto;
  }
}
</style>
