<template>
  <div>
    <div class="main-content bg-primary-white rounded-t-lg p-5">
      <div class="text-base">
        <div class="flex items-center">
          <div class="font-bold min-w-2 md:min-w-3 pb-3">Title:</div>
          <span class="font-normal text-primary-grey-light capitalize">
            {{ notificationTitle }}
          </span>
        </div>
        <div v-if="role" class="flex items-center">
          <div class="font-bold min-w-2 md:min-w-3 pb-3">Role:</div>
          <span class="font-normal text-primary-grey-light capitalize">{{ currentRole }}</span>
        </div>
        <div v-if="emailBody" class="flex items-center pb-3">
          <div class="font-bold min-w-2 md:min-w-3">Email Body:</div>
          <span class="font-normal text-primary-grey-light capitalize">{{ emailBody }}</span>
        </div>
        <div v-if="pushBody" class="flex items-center pb-3">
          <div class="font-bold min-w-2 md:min-w-3">Push Notification Body:</div>
          <span class="font-normal text-primary-grey-light capitalize">{{ pushBody }}</span>
        </div>
        <div v-if="smsBody" class="flex items-center pb-3">
          <div class="font-bold min-w-2 md:min-w-3">Sms Body:</div>
          <span class="font-normal text-primary-grey-light capitalize">{{ smsBody }}</span>
        </div>
        <hr class="border-primary-grey pb-1" />
      </div>
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else-if="!isLoading && isEmpty(currentUsersList)">
        <NoRecordFound />
      </div>
      <div v-else class="mt-5 relative">
        <div
          class="border relative border-primary-grey sm:overflow-x-auto rounded-lg custom-max-w overflow-x-scroll"
        >
          <table class="w-full table-auto whitespace-nowrap">
            <thead class="bg-bright-gray">
              <tr class="ltr:text-left">
                <th
                  v-for="(head, index) in tableHead"
                  :key="index"
                  class="font-roboto font-medium text-base text-text-color rtl:text-right py-3 rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
                >
                  <span
                    class="ltr:border-r rtl:border-l border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                  >
                    {{ head }}
                  </span>
                </th>
                <th
                  v-if="selectedMedium.emailCheck"
                  class="font-roboto font-medium text-base text-text-color rtl:text-right py-3 rtl:pr-4 rtl:pl-0 ltr:pl-3 ltr:pr-0"
                >
                  <span
                    class="ltr:border-r rtl:border-l flex border-primary-grey w-full rtl:pl-3 ltr:pr-3"
                  >
                    <span>
                      <UiCheckBox
                        :model-value="selectAllEmails"
                        type="checkbox"
                        name="medium"
                        rules="required"
                        value="emailChecked"
                        :no-error="true"
                        @change="setEmailMediumForAllUsers($event)"
                      />
                    </span>
                    <span v-i18n="dashboard" class="ml-1 mt-1">Email</span>
                  </span>
                </th>
                <th
                  v-if="selectedMedium.pushnotificationCheck"
                  class="font-roboto font-medium text-base text-text-color rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-3 ltr:pr-0"
                >
                  <span
                    class="ltr:border-r rtl:border-l border-primary-grey w-full flex rtl:pl-3 ltr:pr-3"
                  >
                    <span>
                      <UiCheckBox
                        :model-value="selectAllPushs"
                        type="checkbox"
                        name="medium"
                        rules="required"
                        value="pushChecked"
                        :no-error="true"
                        @change="setPushMediumForAllUsers($event)"
                      />
                    </span>
                    <span v-i18n="dashboard" class="mt-1 ml-1">Push Notification</span>
                  </span>
                </th>
                <th
                  v-if="selectedMedium.smsCheck"
                  class="font-roboto font-medium text-base text-text-color rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-3 ltr:pr-0"
                >
                  <span
                    class="ltr:border-r rtl:border-l border-primary-grey w-full flex rtl:pl-3 ltr:pr-3"
                  >
                    <span>
                      <UiCheckBox
                        :model-value="selectAllSms"
                        type="checkbox"
                        name="medium"
                        :no-error="true"
                        rules="required"
                        value="smsChecked"
                        @change="setSmsMediumForAllUsers($event)"
                      />
                    </span>
                    <span v-i18n="dashboard" class="mt-1 ml-1">Sms</span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, index) in currentUsersList"
                :key="index"
                class="border-t border-primary-grey text-left"
              >
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right max-w-1.25 px-3 py-4 w-min"
                >
                  <span class="flex flex-row font-roboto font-normal text-text-color text-sm px-9">
                    <BadgeDisplay
                      :options="[user]"
                      label="full_name"
                      :image="true"
                      :child-class="badgeWidth"
                    />
                  </span>
                </td>
                <td
                  v-if="selectedMedium.emailCheck"
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0 w-36"
                >
                  <span class="font-roboto my-2 text-base font-normal text-text-color">
                    <UiCheckBox
                      :model-value="user.emailChecked"
                      :disabled="!!!user.email"
                      :no-error="true"
                      type="checkbox"
                      name="medium"
                      rules="required"
                      value="emailChecked"
                      @change="setSelectedMedium($event, user)"
                    />
                  </span>
                </td>
                <td
                  v-if="selectedMedium.pushnotificationCheck"
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0 w-36"
                >
                  <span class="font-roboto font-normal text-text-color text-base">
                    <UiCheckBox
                      :model-value="user.pushChecked"
                      :no-error="true"
                      type="checkbox"
                      name="medium"
                      rules="required"
                      value="pushChecked"
                      :disabled="!user.login_activated_at"
                      @change="setSelectedMedium($event, user)"
                    />
                  </span>
                </td>
                <td
                  v-if="selectedMedium.smsCheck"
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-0 w-36"
                >
                  <span class="font-roboto font-normal text-text-color flex">
                    <UiCheckBox
                      :model-value="user.smsChecked"
                      :disabled="!user.phone"
                      :no-error="true"
                      type="checkbox"
                      name="medium"
                      rules="required"
                      value="smsChecked"
                      @change="setSelectedMedium($event, user)"
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        v-if="usersCount > RECORD_LIMIT"
        v-show="!isLoading"
        child-class="md:mr-5 mr-0"
        :record-limit="RECORD_LIMIT"
        :max-range="paginationCounts(usersCount, RECORD_LIMIT)"
        @filterRecord="filterRecord"
      />
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { objectDeepCopy, transformTextToCapitalize } from '@utils/generalUtil'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import generalMixin from '@src/mixins/general-mixins.js'
import BadgeDisplay from '@components/UiElements/UiBadgeDisplay.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import UiCheckBox from '@components/UiElements/UiCheckbox.vue'
import GENERAL from '@src/constants/general-constants'
import tooltipMixin from '@src/mixins/components/tooltip-mixin.js'
import { removeEmptyKeysFromObject } from '@utils/generalUtil.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { fullName } from '@/src/utils/settings/tenant-user.util'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { getPageNumberWithRange } from '@src/components/BaseComponent/pagination.util.js'
export default {
  name: 'SendAnnouncements',
  components: {
    BadgeDisplay,
    UiCheckBox,
    Pagination,
    NoRecordFound,
    Loader,
  },
  mixins: [generalMixin, tooltipMixin],
  props: {
    users: { type: Array, default: () => [] },
    emailBody: { type: String, default: '' },
    smsBody: { type: String, default: '' },
    pushBody: { type: String, default: '' },
    notificationTitle: { type: String, default: '' },
    announcementType: { type: String, default: '' },
    role: { type: String, default: '' },
    section: { type: String, default: '' },
    currentClass: { type: String, default: '' },
    departmentId: { type: String, default: null },
    selectedMedium: { type: Object, default: () => ({}) },
    fetchCustomPayload: { type: Boolean, default: false },
  },

  emits: ['saveCustomNotification'],
  data() {
    return {
      selectAllEmails: false,
      selectAllPushs: false,
      selectAllSms: false,
      result: 'result',
      title: 'title',
      dashboard: 'dashboard',
      tableHead: ['Name'],
      noRecord: false,
      currentUsersList: [],
      allUsersList: {},
      usersCount: [],
      RECORD_LIMIT: GENERAL.RECORD_LIMIT,
      arrayStart: 0,
      arrayEnd: GENERAL.RECORD_LIMIT,
      forceUpdate: 1,
      updateStatusCount: 0,
      isLoading: false,
    }
  },
  computed: {
    badgeWidth() {
      return this.selectedMedium.emailCheck &&
        this.selectedMedium.pushnotificationCheck &&
        this.selectedMedium.smsCheck
        ? 'w-24 xxl:w-44'
        : 'w-24 md:w-44'
    },
    currentRole() {
      return transformTextToCapitalize(this.role)
    },
    ...mapState({
      showModal: (state) => state.layout.showModal,
    }),
  },
  watch: {
    users: {
      immediate: true,
      handler(list) {
        if (list.length) {
          this.currentUsersList = objectDeepCopy(list)
          this.allUsersList = { 0: this.currentUsersList }
        } else this.filterRecord()
      },
    },
    fetchCustomPayload: {
      handler() {
        this.saveNotification()
      },
    },
    currentUsersList: {
      handler(list) {
        if (list && list.length) this.IsAllUsersSelectedFromusersList()
      },
      deep: true,
    },
    updateStatusCount: {
      handler() {
        this.IsAllUsersSelectedFromusersList()
      },
    },
  },

  methods: {
    isEmpty,
    filterRecord(range) {
      this.getUsersListBasedOnRole(range)
    },
    async getUsersListBasedOnRole(range) {
      this.isLoading = true
      let paginationData = paginationRangeHandler(range, GENERAL.RECORD_LIMIT)
      let response = null
      switch (this.role) {
        case 'staff': {
          let payload = { ...paginationData }
          response = await this.getStaffWithFilters(payload)
          response = response?.data
          break
        }
        case 'section_student': {
          let filtersData = {
            $where: { ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.section) },
          }
          removeEmptyKeysFromObject(filtersData.$where)
          const payload = { ...paginationData, ...filtersData }
          response = await this.getStudentsWithFilters(payload)
          break
        }
        case 'guardian': {
          let filtersData = {
            $where: { ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.section) },
          }
          removeEmptyKeysFromObject(filtersData.$where)
          response = await this.getGuardiansWithFilter()
          break
        }
        case 'section_teacher': {
          let filtersData = {
            $where: { ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.section) },
          }
          removeEmptyKeysFromObject(filtersData.$where)
          const payload = { ...paginationData, ...filtersData }
          response = await this.getTeacherWithFilters(payload)
          response = response?.data
          break
        }
      }

      let pageNo = getPageNumberWithRange(paginationData)
      let isStaffListAlreadyExists = this.allUsersList.hasOwnProperty(`${pageNo}`)
      if (isStaffListAlreadyExists)
        this.currentUsersList = objectDeepCopy(this.allUsersList[`${pageNo}`])
      else {
        this.currentUsersList = objectDeepCopy(response?.records) || []
        this.allUsersList = { ...this.allUsersList, ...{ [pageNo]: response.records } }
      }
      this.usersCount = response.meta.total_records
      this.currentUsersList.map((std) => {
        fullName(std)
      })
      this.isLoading = false
    },

    IsAllUsersSelectedFromusersList() {
      let allEmailsSelected = this.currentUsersList.every(
        (user) => user.emailChecked === true || !user.email,
      )
      let allPushsSelected = this.currentUsersList.every(
        (user) => user.pushChecked === true || !user.login_activated_at,
      )
      let allSmsSelected = this.currentUsersList.every(
        (user) => user.smsChecked === true || !user.phone,
      )
      this.selectAllEmails = allEmailsSelected
      this.selectAllPushs = allPushsSelected
      this.selectAllSms = allSmsSelected
    },
    setStatusByDefaultChecked() {
      // Email Check
      if (this.selectedMedium.emailCheck) {
        this.currentUsersList.forEach((user) => {
          user['emailChecked'] = true
        })
        this.selectAllEmails = true
      }
      // Push Notification Check

      if (this.selectedMedium.pushnotificationCheck) {
        this.currentUsersList.forEach((user) => {
          user['pushChecked'] = true
        })
        this.selectAllPushs = true
      }
      // SMS Service Check
      if (this.selectedMedium.smsCheck) {
        this.currentUsersList.forEach((user) => {
          user['smsChecked'] = true
        })
        this.selectAllSms = true
      }
    },

    saveNotification() {
      this.setStatusByDefaultChecked()
      let users = Object.values(objectDeepCopy(this.currentUsersList)).flat()
      users.forEach((user) => {
        user.mediums = []
        if (user.emailChecked && user.email) {
          user.mediums.push('email')
        }
        if (user.pushChecked && user.login_activated_at) {
          user.mediums.push('webpush')
        }
        if (user.smsChecked && user.phone) {
          user.mediums.push('sms')
        }
      })
      const payload = {
        receiver_role_id: this.role,
        receivers: users.filter((user) => user.mediums && user.mediums.length),
        department_id: this.departmentId,
        title: this.notificationTitle,
        message_body: this.smsBody,
        email_body: this.emailBody,
        webpush_body: this.pushBody,
        announcement_type_id: this.announcementType,
        section_id: this.section,
        class_id: this.currentClass,
      }

      this.$emit('saveCustomNotification', payload)
    },

    setSelectedMedium($event, user) {
      user[$event.value] = $event.status
      this.updateStatusCount++
    },

    setEmailMediumForAllUsers($event) {
      this.currentUsersList.forEach((user) => {
        if (user.email) user[$event.value] = $event.status
      })
      this.selectAllEmails = $event.status
    },

    setPushMediumForAllUsers($event) {
      this.currentUsersList.forEach((user) => {
        user[$event.value] = $event.status
      })
      this.selectAllPushs = $event.status
    },

    setSmsMediumForAllUsers($event) {
      this.currentUsersList.forEach((user) => {
        if (user.phone) user[$event.value] = $event.status
      })
      this.selectAllSms = $event.status
    },
    ...mapActions('notifications', ['getStaffData', 'createAnnouncemnt']),
    ...mapActions('student', ['getStudentsWithFilters']),
    ...mapActions('guardian', ['getGuardiansWithFilter']),
    ...mapActions('staff', ['getStaffWithFilters']),
    ...mapActions('teacher', ['getTeacherWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItem {
  min-width: 50%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>

<style lang="scss" scoped>
th:last-child span {
  border-right: none;
}

@media (min-width: 400px) and (max-width: 499px) {
  .custom-max-w {
    width: 335px;
  }
}

@media (max-width: 399px) {
  .custom-max-w {
    width: 292px;
  }
}
</style>
